import React from 'react'
import styled from 'styled-components'
import MainGrid from '../styling/MainGrid'
import icon1 from '../../images/sponsorships/sponsorship_Essex_Cricket.png'
import icon2 from '../../images/sponsorships/sponsorships_bsfc.png'
import icon3 from '../../images/sponsorships/tom_gamble.jpg'
import icon4 from '../../images/sponsorships/sponsorship_lordtaverners.png'
import icon5 from '../../images/sponsorships/sponsorship_wildlife.png'
import icon6 from '../../images/sponsorships/sponsorship_bhf.jpg'

import icon7 from '../../images/sponsorships/sponsorship_hamster.jpeg'
import icon9 from '../../images/sponsorships/sponsorship_harvering_cricket.jpg'
import icon10 from '../../images/sponsorships/sponsorship_forcolchester_swimming.jpg'
import icon11 from '../../images/sponsorships/sponsorship_dan.png'
import icon12 from '../../images/sponsorships/charlie_fagg.jpg'
import icon13 from '../../images/sponsorships/update_dan_cammish.jpg'
import icon14 from '../../images/sponsorships/ram_racing.jpg'


interface ISponsorshipsGrid {
  heading?: string
}
const SponsorshipsGrid = ({ heading }: ISponsorshipsGrid) => {
  return (
    <Container>
      <MainGrid>
        {heading && <h4>{heading}</h4>}

        <Grid>

          <Card>
            <Icon src={icon3} alt={'Tom Gamble'} />
            <Text>
              <h6>Tom Gamble</h6>
              <p>
                The 2017 BRDC Autosport young driver of the year, Tom Gamble is continuing to shine across the globe in both sportscar and prototype racing. For 2022 Tom will have a busy schedule as he will be competing in the Asian Le Mans Series, USA based IMSA sportscar championship which includes the blue ribbon Daytona 24 hour race as-well as his role in the European Le mans Series with United Autosports in LMP2. Tom is one of title favourites for 2022 where he and his two team mates will attempt to take the coveted 2022 European Le mans title.
              </p>
            </Text>
          </Card>

          <Card>
            <Icon src={icon13} alt={'Dan Cammish'} />
            <Text>
              <h6>Dan Cammish</h6>
              <p>The Yorkshire-born driver straightaway showed his talent by winning the Scholarship class in MSA Formula Ford. He also took part in the final three rounds of that season’s ADAC Formel Masters series in Germany, scoring two fifth-place finishes. His 2013 season in Formula Ford EcoBoost 200 is the stuff of legend, winning all 24 of the races in the championship, with 22 pole positions and 19 fastest laps. Dan Cammish has enjoyed success at every stage of his racing career, culminating in back-to-back Porsche Carrera Cup GB titles in 2015 and ’16. In 2021 Dan made a return to the Porsche Carrera Cup GB, with Team Redline Racing, where he became a record braker by claiming his third overall championship title in the series. For 2022 Dan returns to the BTCC with the established Motorbase team who along with backing from American Auto Parts giant Napa have created the newly formed Napa Racing UK.</p>
            </Text>
          </Card>

          

          <Card>
            <Icon src={icon14} alt={'Ram Racing'} />
            <Text>
              <h6>Ram Racing</h6>
              <p>Ram Racing was created in 2012 by former Mercedes F1 chief mechanic, Dan Shufflebottom, RAM Racing has grown to become one of the leading sportscar racing teams in the UK. Building on his experience with the BAR, Honda, Brawn GP and Mercedes Formula 1 teams, Dan has been able to assemble a world-class crew, which operates out of its base near Silverstone, the home of British motor racing.<br /><br />For the 2022 season, RAM Racing is running twin Mercedes-AMG GT3 machines in the Intelligent Money British GT Championship, and the GT Cup Championship.</p>
            </Text>
          </Card>
         
        </Grid>
      </MainGrid>
    </Container>
  )
}

export default SponsorshipsGrid

const Container = styled.section`
  padding-bottom: 50px;

  @media (min-width: 1024px) {
    padding-bottom: 80px;
  }
  & h4 {
    margin-bottom: 20px;
    @media (min-width: 768px) {
      margin-bottom: 26px;
    }
  }
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 20px;
  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 30px;
    grid-row-gap: 40px;
  }
`

const Card = styled.div`
  border-radius: 4px;
  display: flex;
  flex-direction: column;

  & h6 {
    font-family: Roboto, sans-serif;
    font-weight: bold;
    margin-bottom: 10px;

    @media (min-width: 768px) {
      margin-bottom: 17px;
    }
  }

  & p {
    margin-bottom: 24px;
    flex: 1;
    @media (min-width: 1024px) {
      margin-bottom: 38px;
    }
  }

  & a,
  & a:link,
  & a:visited {
    font-family: Roboto;
    font-weight: 300;
    text-decoration: none;
    color: #006822;
  }
  & a:hover,
  & a:active {
    text-decoration: underline;
  }
`

const Icon = styled.img`
  min-height: 350px;
  max-height: 350px;
  width: 100%;
  object-fit: cover;
  border-radius: 4px;
  @media (min-width: 1024px) {
    margin-bottom: 14px;
    min-height: 380px;
  }
`
const Text = styled.div`
  border: 1px solid #eeeeee;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
  padding: 42px 27px 53px;
  margin-left: 36px;
  margin-top: -120px;
  @media (min-width: 768px) {
    padding: 50px 40px 48px;
    width: 92%;
    margin-left: auto;
    height: auto;
    min-height: 345px;
  }
`
