import React from 'react'
import Layout from '../components/Layout'
import CallBackBanner from '../components/CallBackBanner'
import GeneralHeader from '../components/Header/GeneralHeader'
import GeneralEnquiryForm from '../components/shared/Form/GeneralEnquiryForm'
import ImageAndTextColumns from '../components/ImageAndTextColumns/ImageAndTextColumns'
import img from '../images/sponsorships/sponsorship.svg'

import SponsorshipsGrid from '../components/SponsorshipsGrid/SponsorshipsGrid'

const breadcrumbData = [
  {
    title: 'Home',
    url: '/',
  },
  {
    title: 'Sponsorships',
    url: '/sponsorships',
  },
]

const Sponsorships = () => {
  return (
    <Layout
      activeLink="/sponsorships"
      title="R&D Sponsorships"
      description="R&D Sponsorships"
    >
      <GeneralHeader
        breadcrumbData={breadcrumbData}
        heading="Our sponsorships"
      />

      <ImageAndTextColumns
        tag="COMMITTED TO CREATING OPPORTUNITIES"
        heading="Creating opportunities for British business"
        body={[
          'At RDA we are a local firm with a national focus, and we believe passionately in creating opportunities for British business. We have generated millions of pounds in cash remittances from HMRC for our clients in recognition of the imagination, the innovation and the iteration their R&D has involved.',
          'But we also believe in inspiration. That is why we are committed to creating opportunities in the sport and sustainability sectors through our sponsorship partners. Our managing director, Kevin Auchoybur, explains: “I’ve always believed there’s such a close connection with sport and business.',
          '“They both involve commitment and discipline, and they’re two huge things in what we do as a firm. In that regard, we are the same as a sportsperson. We make a commitment to our clients and we show the discipline to deliver the best relationship and the best results for them.”',
        ]}
        img={img}
        imgAlt="People in a meeting"
      />
      <SponsorshipsGrid heading="Our sponsorships include:" />
      <GeneralEnquiryForm />
      <CallBackBanner />
    </Layout>
  )
}

export default Sponsorships
